<template>
  <section class="section is-main-section">
     <div class="columns">
        <div class="column is-one-third">
          <card-component
                  title="1. Set clustering options"
                  class="tile is-child"
                  :displayHelp="$options.name"
                >
                <validation-observer v-slot="{ handleSubmit }">
                  <validation-provider
                    v-slot="{ errors }"
                    :rules= {required:true}
                    name="dataset"
                  >
                    <dataset-select
                      label="Select a dataset"
                      :dataset.sync="datasetName"
                      v-model="datasetName"
                      :withVarname="false"
                      icon="table"
                      pack="fa"
                      :message="errors"
                      :type="{ 'is-danger': errors[0] }"
                      expanded
                      />
                  </validation-provider>
                  <b-field
                    label="Select a clustering method"
                    message="k-means is more appropriate for a large number of individuals but it does not cope well with many variables."
                  >
                    <b-select
                      v-model="method"
                      placeholder="Clustering methods"
                      class="mb-2"
                      expanded
                    >
                      <option value="hac">HC</option>
                      <option value="kmeans">k-means</option>
                    </b-select>
                  </b-field>
                  <div v-show="method==='kmeans'">
                    <b-field grouped position="is-right">
                      <b-button rounded
                        size="is-small"
                        type="is-text"
                        v-show="method==='kmeans'"
                        @click="showExpert = !showExpert"
                        ><span class="icon is-small"><i :class="{'fa fa-plus': !showExpert, 'fa fa-minus': showExpert}"></i></span>
                          <span> Advanced options </span>
                      </b-button>
                    </b-field>
                    <div v-if="showExpert">
                      <validation-provider
                            v-slot="{ errors, valid}"
                            :rules= {integer:true,min_value:2,max_value:maxClusterAccepted}
                            name="kmin"
                        >
                        <b-field
                          label="Minimum number of clusters"
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-input
                            v-model="kmin"
                            expanded
                          ></b-input>
                        </b-field>
                      </validation-provider>
                      <validation-provider
                          v-slot="{ errors, valid}"
                          :rules= {integer:true,min_value:2,max_value:maxClusterAccepted}
                          name="kmax"
                        >
                        <b-field
                          label="Maximum number of clusters"
                          :type="{ 'is-danger': errors[0], 'is-success': valid }"
                          :message="errors"
                        >
                          <b-input
                            v-model="kmax"
                            expanded
                          ></b-input>
                        </b-field>
                      </validation-provider>
                      <validation-provider
                          v-slot="{ errors }"
                          :rules= {integer:true,min_value:1,max_value:65535}
                          name="seed"
                        >
                        <b-field
                          label="Seed"
                          :type="{ 'is-danger': errors[0] }"
                          :message="messageSeed(errors)"
                        >
                          <b-input
                            v-model="seed"
                            expanded
                          ></b-input>
                          </b-field>
                      </validation-provider>
                    </div>
                  </div>
                  <hr />
                  <div class="has-text-centered">
                    <b-button rounded
                      class="is-primary button-shadow"
                      @click="handleSubmit(run)"
                    >
                      <span class="icon is-small"><i class="fa fa-search"></i></span>
                      <span> Run </span>
                    </b-button>
                  </div>
              </validation-observer>
            </card-component>
        </div>
        <div class="column">
            <card-component
              title="2. Explore clustering"
              >
            </card-component>
            <error :type='"notifications"' />
            <b-notification
                v-if="hasMissing && method==='kmeans'"
                type="is-warning"
                aria-close-label="Close notification"
                role="alert">
                  The selected dataset has missing values, kmeans cannot be run on this dataset. Please process this dataset with <a href="/edit/missing">missing workflow</a> before.
            </b-notification>
            <card-no-result v-show="!resultsLoaded">
              <strong>How to set options?<br></strong>
              <p>
                Options will help you obtain different quality metrics for
                different values of the number of clusters for a given
                clustering method:<br>
              </p>
              <p>
                <ul>
                  <li>if you select the <strong>hierarchical clustering method
                  (HC)</strong>, you will be able to explore all possible values
                  of the number of clusters but the method is very slow for a
                  large number of individuals;</li><br>
                  <li>if you select the <strong>k-means method</strong>, you
                  will be able to explore a limited number of numbers of
                  clusters (20 at maximum), obtained by setting a minimum and a
                  maximum number, but the method is more efficient in terms of
                  number of individuals and is meant to provide better
                  clusters.</li>
                </ul>
                <br>
              </p>
              <p>
                We advise (if your dataset size permits it) that you try the two
                clustering methods and different numbers of clusters, trying to
                check the differences between the resulting clusterings.
              </p>
              <br>
              <p>
                <strong>Important note</strong>: The clustering is performed
                with raw values (data are not scaled to unit variance before
                they are processed). <strong>If you want to perform it on scaled
                data</strong>, go to the menu “Edit / Dataset edition” and scale
                your dataset before the clustering.
              </p>
            </card-no-result>
            <card-component v-if="resultsLoaded">
                  <p v-if="brokenStick">Number of clusters chosen by broken stick: {{brokenStick}}</p>
                  <hr v-if="brokenStick" />
                  <plotly
                      v-if="graphicals.length===1"
                      :data="graphicals[0]"
                    ></plotly>
                    <plotly-list
                      v-else-if="graphicals.length>1"
                      :data="graphicals"
                    ></plotly-list>
                    <p v-else>
                      No graphics available go to next step to explore clusters.
                    </p>
            </card-component>
        </div>
      </div>
  </section>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { useWorkspaceStore } from '@/stores/workspacestore'
export default {
  name: 'RClusteringStep',
  props: {
    analysisObj: {
      type: Object,
      default: null
    }
  },
  components: {
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'dataset-select': () => import('@/components/ui/DatasetSelect.vue'),
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'error': () => import('@/components/ui/Error.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue')
  },
  mixins: [ranalysis],
  data () {
    return {
      kmeansObjects: null,
      datasetName: null,
      selectedDatasetObject: null,
      method: 'hac',
      kmin: 2,
      kmax: 10,
      seed: '',
      showExpert: false,
      currentResult: 'plots',
      clusteringObjectName: null,
      brokenStick: null
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    getSeed: function () {
      return this.workspaceStore.getSeed
    },
    title: function () {
      return '1. Explore clustering of ' + this.args.datasetName + ' with ' + this.args.method
    },
    params: function () {
      let params = {
        'func_name': 'r_clustering',
        'datasetName': this.datasetName,
        'method': this.method,
        'out_graph': true
      }
      if (this.method === 'kmeans') {
        params['kmin'] = parseInt(this.kmin)
        if (this.kmax !== '') {
          params['kmax'] = parseInt(this.kmax)
        }
        if (this.seed !== '') {
          params['seed'] = parseInt(this.seed)
        }
      }
      return params
    },
    disableButton: function () {
      if (this.hasMissing && this.method === 'kmeans') {
        return true
      } else if (this.datasetName === null) {
        return true
      }
      return false
    },
    maxClusterAccepted: function () {
      if (this.selectedDatasetObject !== null) {
        if (this.selectedDatasetObject.meta.nrow < 11) {
          return (this.selectedDatasetObject.meta.nrow - 1)
        } else {
          return (this.selectedDatasetObject.meta.nrow / 5).toFixed(0)
        }
      } else {
        return 1
      }
    },
    hasMissing: function () {
      if (this.selectedDatasetObject !== null) {
        return (this.selectedDatasetObject.meta.nbmissing > 0)
      } else {
        return false
      }
    }
  },
  mounted () {
    if (this.seed === '') {
      this.seed = this.getSeed
    }
  },
  watch: {
    datasetName: function () {
      if (this.datasetName) {
        this.selectedDatasetObject = this.workspaceStore.datasets.filter(obj => obj.object_name === this.datasetName)[0]
        // not init kmax if displaying an allready computed analysis.
        if (this.analysisObj === null) {
          this.kmax = Math.min(10, this.selectedDatasetObject.meta.nrow)
        }
      }
    }
  },
  methods: {
    messageSeed: function (err) {
      let msg = 'You can fix the random seed used to ensure reproductibility of the k-means analysis.'
      if (err.length > 0) {
        msg += ' Error: ' + err
      }
      return msg
    },
    beforeRun: function () {
      this.brokenStick = null
    },
    afterRun: function (data) {
      let defaultCut = this.params['kmin']
      if (data.BS) {
        this.brokenStick = data.BS
        defaultCut = this.brokenStick
      }
      this.clusteringObjectName = data.ObjectName
      this.kmeansObjects = null
      if (typeof this.clusteringObjectName !== 'string') {
        // si k-means recois un tableau d'object name avec à l'indice 0 l'object principal
        this.clusteringObjectName = data.ObjectName[0]
        this.kmeansObjects = data.ObjectName
      }
      if (this.clusteringObjectName !== null) {
        this.$emit('is-computed',
          { 'clusteringObjectName': this.clusteringObjectName,
            'defaultCut': defaultCut,
            'kmeansObjects': this.kmeansObjects,
            'datasetUserName': this.selectedDatasetObject.user_name
          }
        )
      }
    },
    displayResult: function (value) {
      this.currentResult = value
    }
  }
}
</script>
<style>
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
