<template>
    <div class="columns">
      <div class="column is-one-third">
        <card-component
          :title="title"
          :displayHelp="$options.name"
          >
          <validation-observer v-slot="{ handleSubmit }">
          <validation-provider
            v-slot="{ errors, valid}"
            :rules= {required:true,integer:true,min_value:args.kmin,max_value:args.kmax}
            name="Number of clusters"
          >
            <b-field
              label="Number of clusters"
              :type="{ 'is-danger': errors[0], 'is-success': valid }"
              :message="errors"
            >
              <b-input
                v-model="k"
                expanded
              ></b-input>
            </b-field>
          </validation-provider>
          <font size="2"><p style="color:red;">Warning! This step might be long. Wait for a few minutes to obtain the result.</p></font>
          <hr />
          <div class="has-text-centered">
            <b-button rounded
              class="is-primary button-shadow"
              @click="handleSubmit(run)"
            >
              <span class="icon is-small"><i class="fa fa-search"></i></span>
              <span> Explore </span>
            </b-button>
          </div>
          </validation-observer>
        </card-component>
      </div>
      <div class="column">
        <card-component
            title="2. Explore clustering"
          >
        </card-component>
        <card-no-result v-if="!resultsLoaded" wfclass="RPCA">
          <strong>How to set options?<br></strong>
          Set a number of clusters on the left panel to explore a clustering.
        </card-no-result>
        <div v-else>
          <error :type='"notifications"' />
          <card-component>
            <b-tabs @input="displayResult">
              <b-tab-item label="Heatmap" icon="bar-chart" value="heatmap" />
              <b-tab-item label="Plots" icon="bar-chart" value="plots" />
              <b-tab-item label="Frequency table" icon="table" value="summary" />
              <b-tab-item label="Clusters" icon="th" value="dataset" />
            </b-tabs>
          </card-component>
          <card-component v-if="currentResult==='heatmap'">
            <card-component v-if="heatmap.type==='png'">
              <png :data="heatmap" />
            </card-component>
            <plotly
              v-else
              :data="heatmap"
            />
          </card-component>
          <card-component v-if="currentResult==='plots'">
            <plotly
                v-if="graphicals.length===1"
                :data="graphicals[0]"
              ></plotly>
              <plotly-list
                v-else-if="graphicals.length>1"
                :data="graphicals"
              ></plotly-list>
          </card-component>
          <card-component
              v-if="currentResult==='summary'"
              :title="basicTables[0].title"
            >
              <basic-table :data="basicTables[0]"/>
          </card-component>
          <card-component
            v-if="currentResult==='dataset'"
            title="Cluster by individual (row)"
            >
            <data-view :data="dataViews[0]" :showClassInfo="true" :sort="true"/>
            <br>
            <r-extract-cluster :datasetName="cutObjectName" :baseName="datasetUserName"></r-extract-cluster>
          </card-component>
        </div>
      </div>
    </div>
</template>

<script>
import { ranalysis } from '@/mixins/ranalysis'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm'
import { useWorkspaceStore } from '@/stores/workspacestore'
let method = { 'kmeans': 'k-means', 'hac': 'HC' }
export default {
  name: 'RCutClustering',
  components: {
    'card-component': () => import('@/components/ui/CardComponent.vue'),
    'data-view': () => import('@/components/rresults/DataView.vue'),
    'plotly-list': () => import('@/components/ui/PlotlyList.vue'),
    'plotly': () => import('@/components/rresults/Plotly.vue'),
    'basic-table': () => import('@/components/rresults/BasicTable.vue'),
    'validation-observer': ValidationObserver,
    'validation-provider': ValidationProvider,
    'error': () => import('@/components/ui/Error.vue'),
    'r-extract-cluster': () => import('@/components/ranalyses/RExtractDataset.vue'),
    'card-no-result': () => import('@/components/ui/CardNoResult.vue'),
    'png': () => import('@/components/rresults/PNG.vue')
  },
  mixins: [ranalysis],
  props: {
    analysisObj: {
      type: Object,
      default: null
    },
    clusteringObjectName: {
      type: String,
      default: ''
    },
    nbClusterRes: {
      type: Number,
      default: 0
    },
    kmeansObjects: {
      type: Object
    },
    datasetUserName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      k: 0,
      args: { 'method': '',
        'kmin': 0,
        'kmax': 0,
        'datasetName': null },
      cutObjectName: null,
      newDatasetName: '',
      currentResult: 'heatmap',
      heatmap: null,
      extracted: false,
      extractedSuccess: false
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    return { workspaceStore }
  },
  computed: {
    title: function () {
      let curmethod = method[this.args.method]
      if (!curmethod) {
        curmethod = this.args.method
      }
      return '1. Explore clustering of ' + this.datasetUserName + ' with ' + curmethod
    },
    params: function () {
      let params = {
        'func_name': 'r_cut_clustering',
        'datasetName': this.clusteringObjectName,
        'out_graph': true,
        'k': parseInt(this.k)
      }
      if (this.args.method === 'kmeans') {
        if (this.args.kmin < this.args.kmax) {
          // si on est sur un clustering kmean, on va chercher l'objet associé au k déja calculé
          params['datasetName'] = this.kmeansObjects[parseInt(this.k)]
        }
      }
      return params
    },
    excludeResults: function () {
      return ['Heatmap']
    }
  },
  mounted () {
    this.setDefaultValues()
  },
  watch: {
    clusteringObjectName: function () {
      this.setDefaultValues()
    }
  },
  methods: {
    displayResult: function (value) {
      this.currentResult = value
    },
    beforeRun: function () {
      this.cutObjectName = null
      this.newDatasetName = ''
      this.currentResult = 'heatmap'
    },
    setDefaultValues: function () {
      if (this.analysisObj === null) {
        this.k = this.nbClusterRes
      }
      let analysis = this.workspaceStore.analyses.find(obj => obj.object_name === this.clusteringObjectName)
      if (analysis) {
        this.args = analysis.meta.func_args
        this.args.kmin = parseInt(this.args.kmin)
        this.args.kmax = parseInt(this.args.kmax)
      }
      // if comming from kmean and have only one result -> run directly cut_clustering
      if (this.clusteringObjectName.startsWith('KmeansCut')) {
        this.args.method = 'kmeans'
        this.args.kmin = this.nbClusterRes
        this.args.kmax = this.nbClusterRes
        this.run()
      }
    },
    afterRun: function (data) {
      this.newDatasetName = data.ObjectName
      this.cutObjectName = data.ObjectName
      this.heatmap = data.Heatmap
    }
  }
}
</script>
<style>
  .b-tabs .tab-content {
    padding: 0rem;
  }
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
