<template>
  <div>
    <r-clustering v-show="selectedItem==='r_clustering'" ref="clusteringStep"
      @is-computed="isComputed"
      :analysisObj="getAnalysisForStep('r_clustering')"
    />
    <r-cut-clustering v-if="clusteringObjectName" v-show="selectedItem==='r_cut_clustering'"
      :clusteringObjectName="clusteringObjectName"
      :datasetUserName="datasetUserName"
      :nbClusterRes="defaultCut"
      :kmeansObjects="kmeansObjects"
      ref="cutClustering"
      :analysisObj="getAnalysisForStep('r_cut_clustering')"
    />
  </div>
</template>

<script>
import RClustering from '@/components/ranalyses/RClusteringStep.vue'
import RCutClustering from '@/components/ranalyses/RCutClustering.vue'
import { menu } from '@/mixins/menu'
import { useMenuStore } from '@/stores/menu'
import { workflow } from '@/mixins/workflow'
import { useWorkspaceStore } from '@/stores/workspacestore'

export default {
  name: 'RClusteringWorkflow',
  props: {
    analysisObj: {
      type: Object,
      default: null
    }
  },
  components: {
    'r-clustering': RClustering,
    'r-cut-clustering': RCutClustering
  },
  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          name: 'r_clustering',
          title: 'Choose the number of clusters',
          disabled: false
        },
        {
          name: 'r_cut_clustering',
          title: 'Make and explore clusters',
          disabled: true
        }
      ],
      clusteringObjectName: null,
      defaultCut: 2,
      kmeansObjects: null,
      datasetUserName: ''
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },
  mounted () {
    if (!this.workspaceStore.hasDataset) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      })
      this.workspaceStore.getWorkflow()
        .then(() => {
          loadingComponent.close()
        })
        .catch(() => {
          loadingComponent.close()
        })
    }
    if (this.analysisObj !== null) {
      if (this.analysisObj.meta.func_name === 'r_cut_clustering') {
        this.clusteringObjectName = this.analysisObj.meta.func_args.datasetName
        this.menuItems[1].disabled = false
        this.menuStore.selectItem(this.menuItems[1])
      }
    }
  },
  watch: {
    clusteringObjectName: function () {
      this.menuItems[1].disabled = this.clusteringObjectName === null
    }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    }
  },
  methods: {
    isComputed: function (cluteringObjRes) {
      this.clusteringObjectName = cluteringObjRes.clusteringObjectName
      this.datasetUserName = cluteringObjRes.datasetUserName
      this.defaultCut = cluteringObjRes.defaultCut
      this.kmeansObjects = cluteringObjRes.kmeansObjects
      if (this.$refs.cutClustering) {
        this.$refs.cutClustering.reset()
      }
      this.menuItems[1].disabled = this.clusteringObjectName === null
    }
  }
}
</script>

<style>
  .tabs li.is-active a {
    border-bottom-color: #1896E1 !important;
    color: #1896E1 !important;
  }
</style>
